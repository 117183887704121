<template>
    <el-radio-group v-model="data[item.key]" :disabled="item.disabled">
        <el-radio
            v-for="rd in item.options"
            :key="rd.value"
            :label="rd.value"
            >{{ rd.label }}</el-radio
        >
    </el-radio-group>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            default: null
        },
        data: {
            type: Object,
            default: null
        }
    },
    mounted() {
        if (!this.data[this.item.key] && this.item.default !== null) {
            this.$set(this.data, this.item.key, this.item.default);
        }
    }
};
</script>